@import "../../styles/abstract/variables";

.room-img-content {
  .img-card-content {
    display: flex;
    flex-wrap: wrap;
    .card-content {
      padding-left: unset;
      .complain-content {
        border: 1px solid $gray-color;
        background-color: $light-gray-color;
        width: fit-content;
        .img-data-content {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: unsets;
          @media (max-width: $md) {
            display: unset;
            justify-content: center;
          }
          .img-data {
            width: 200px;
            height: 170px;
            padding: 10px;

            @media (max-width: 1600px) {
              width: 200px;
            }
            @media (max-width: 1240px) {
              width: 130px;
              height: 135px;
              padding-right: 0px;
            }
            @media (max-width: 1040px) {
              width: 140px;
            }

            @media (max-width: 765px) {
              width: 100%;
              height: 100%;
            }
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
