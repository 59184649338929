@import "../../styles/abstract/variables";

.header-content {
  background: $light-blue-color;
  padding-top: 20px;
  padding-bottom: 20px;

  @media (max-width: $sm) {
    .column {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .header-title {
    h1 {
      font-family: $OpenSans-Bold;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      span {
        text-decoration: underline;
      }
    }
    p {
      font-family: $OpenSans-Regular;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
  }
  .duration {
    h4 {
      font-family: $OpenSans-Regular;
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
    }
  }
}
