@import "../../styles/abstract/variables";
.image-preview {
  .image-previews {
    border: 2px solid $gray-color;
    // max-height: 274px;
  }
}
.decision-section {
  padding: 100px 0;
}
