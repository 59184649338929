$width: 90;
$height: 51.96;

@import "./Hexagon";

#header {
    position: relative;

    .header_navbar {
        background-color: $header;
        padding: 1.25rem 2rem 1.25rem .75rem;
    }

    #header-dropdown {
        .username_arrow {
            display: none !important;
            margin-left: -4px;
        }
        align-items: center;

        #username-dropdown {
            color: $white;
            -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url('./select.svg');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
    margin-right: 0;
    padding-right: 2rem;
        font-size: 16px;

            &::after {
                display: none;
            }
        }

        

        .dropdown-menu {
            & > a {
                color: $color82;
                font-size: 18px;
            }
        }
    }

    .header_logo--block {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .hexagon {
            @extend %hexagon;

            margin-top: -1rem;
        }

        .header_logo {
            position: relative;
            top: 0;
            left: 50%;
            transform: translate(-50%, -1rem);
            width: #{$width - 10}px;
            
        }
    }
}
