@import "../../styles/abstract/variables";

.img-reorder {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 1rem;

  .example-list-container {
    display: flex;
    flex-wrap: wrap;
    .dl-item {
      max-width: 22%;
      margin: 0% 1.5%;
    }

    @media (max-width: $sm) {
      .dl-item {
        max-width: 47%;
      }
    }
  }

  .image-wrapper-div {
    // max-width: 22%;
    // margin: 0% 1.5%;
    p {
      text-align: center;
      margin-bottom: 1px;
      font-size: 11px;
    }

    .image-wrapper-reorder {
      flex: 50%;
      padding: 30px;
      border: 1px solid #2d9bf0;
      border-style: dashed;
      margin-bottom: 50px;
      height: 100%;
      @media (max-width: $sm) {
        flex: unset;
        max-width: unset;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.dragged {
  .image-wrapper-div {
    opacity: 0.1;
  }
}

.floating {
  .image-wrapper-div {
    // background-color: #ffffff;
    box-shadow: 0 0.2rem 2rem #666666;
  }
}

.close-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 1px solid !important;
  z-index: 10;
  height: 30px;
  width: 30px;
  position: absolute;
  top: -15px;
  right: -15px;
  opacity: 1 !important;
  color: red !important;
  background-color: rgba($color: #fff, $alpha: 0.5) !important;
  span {
    font-weight: bold;
  }
  &:hover{
    opacity: 0.5 !important;
  }
}

.ok-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border: 1px solid !important;
  z-index: 10;
  height: 30px;
  width: 30px;
  position: absolute;
  top: -15px;
  right: -15px;
  opacity: 1 !important;
  color: green !important;
  background-color: rgba($color: #fff, $alpha: 0.5) !important;
  span {
    font-weight: bold;
  }
  &:hover{
    opacity: 0.5 !important;
  }
}

.margin-btm{
  margin-bottom: 36px;
}