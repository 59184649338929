@import "../../styles/abstract/variables";
.issues-section {
  border: 2px solid $gray-color;
  padding: 10px;

  .form-check {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .form-check-label {
      background: $reject-color;
      font-family: $OpenSans-Regular;
      border: 1px solid $black-color;
      padding: 5px;
      height: auto;
      /* min-height: 50px; */
      margin-left: 20px;
      width: 60%;
      max-width: 300px;
      // font-weight: 600;
      font-size: 13px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .form-check-input[type="radio"] {
      border-color: $black-color;
      border-width: 2px;
      width: 25px;
      height: 20px;

      &:checked {
        background-color: $reject-color;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
}
