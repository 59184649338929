.main {
  font-size: 15px;
}
.main .contain {
  display: flex;
  flex-direction: column;
  margin: 0px 90px;
}
.main .contain .searchbox {
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
  display: flex;
}
.main .contain .searchcontain {
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
}

.primarybutton {
  margin-right: 5px;
  padding: 0px !important;
}

// .main .contain .searchbox input{
//   justify-content: flex-end;
//   width: 30%;
//   padding: 5px;
//   border-radius: 4px;
//   background-color: #8282828E;
//   border:1px solid grey;
//   color: black;
// }
// .main .contain .searchbox .searchicon{
//     position: absolute;
// text-align: center;
// }
.main .contain .table {
  display: flex;
  flex: 1;
  width: 100%;
  overflow-x: scroll;
  overflow-y: scroll;
  flex-direction: column;
}
.table th {
  color: #537bb8;
  border-top: 0px !important;
  border-bottom: 0px !important;
  padding: 10px;
  text-align: left;
}
.table td {
  padding: 5px !important;
  border: 0px !important;
  text-align: left;
}
.table .bordertr {
  border-bottom: 1px solid #707070;
  // border:1px solid #707070;
}
.table .bordertr img {
  cursor: pointer;
}
.main .contain .table td img {
  font-size: 15px;
  margin-right: 0px;
  width: 24px;
  height: 20px;
}
.table td button {
  width: 80px;
  background-color: #537bb8;
  color: white;
  border: 2px solid;
  border-radius: 5px;
  height: 30px;
}
.iconimage {
  width: 51px !important;
  margin-right: 0px !important;
  height: 35px !important;
}
.npspac {
  text-align: center;
  text-align: center;
  padding: 4px !important;
  width: 51px !important;
}
.npspacforheader {
  text-align: center !important;
  padding: 10px 2px !important;
  width: 51px !important;
}
.npspac .npspacimg {
  width: 52px !important;
  margin-right: 0px !important;
  height: 40px !important;
}

.page-link {
  position: unset !important;
}
// -----------------------------------------------------------------
.popupcontent {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.selecterrors {
  flex: 1 1;
  border-right: 1px solid grey;
  color: #537bb8;
  padding: 4px;
  max-height: 300px;
}
.selecterrors ul {
  list-style-type: none;
}
.selecterrors p {
  font-size: 15px;
}
.selecterrors .additemicons {
  width: 20px;
  margin: 8px;
}
.addcomments {
  color: #537bb8;
  // flex: 1;
  width: 28%;
  display: flex;
  flex-direction: column;
  padding: 4px 10px;
  align-items: flex-end;
}
.addcomments p {
  font-size: 20px;
}
.addcomments .checkicons {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  font-size: 20px;
}
.addcomments .checkicons .checkiconcontent {
  padding: 7px;
  width: 50%;
  font-size: 15px;
}
.addcomments .checkicons .checkiconcontent checkicon {
  font-size: 30px !important;
}
.addcomments .closebutton {
  align-items: flex-start;
  margin-bottom: 10px;
}
.addcomments .savebutton {
  background-color: #6a9eea;
  color: white;
  width: 150px;
  height: 50px;
  background: #6a9eea 0% 0% no-repeat padding-box;
  border-radius: 7px;
  border: 1px solid #6a9eea;
}
.addcomments .closeicon {
  color: #537bb8;
  font-size: 25px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px;
}
.founderror2 .closeicon2 {
  color: #537bb8;
  font-size: 25px;
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px !important;
}
.addcomments textarea {
  margin: 0 0px 0px 0px;
  color: #537bb8;
  border: 1px solid #707070;
  border-radius: 13px;
  padding-left: 5px;
  width: 100%;
}
.ul {
  list-style-type: none;
}
.resloved {
  color: #537bb8;
  text-align: center;
}
.resloved .closeresolved {
  text-align: right;
  font-size: 20px;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 15px;
}
.resloved .contentresolved {
  text-align: center;
}
.resloved .approvedbutton {
  text-align: center;
}
.resloved .approvedbutton button {
  margin-top: 20px;
  color: white;
  width: 150px;
  height: 50px;
  background: #6a9eea 0% 0% no-repeat padding-box;
  border-radius: 7px;
  border: 1px solid #6a9eea;
}
.ShowErrorPopup {
  display: flex;
}
.alignth {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
//   .sorticon{
//  width: 60%;
//  font-size: 25px;
//   }
.thcontent {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.thcontent .thcontent1 {
  font-size: 19px;
}

.thcontent .thcontent2 {
  width: 30%;
  padding: 0px !important;
}
.thcontent .thcontent1 .sorticon {
  font-size: 26px;
}

.accesslink {
  padding: 10px 0px !important;
  margin: 0px;
}
.accesslink img {
  height: 25px !important;
  margin-right: 2.3px !important;
  padding-right: 2px;
}
#ClaimsPagination {
  .pagination {
    @media (min-width: $lg) {
      position: none;
      float: right;
    }
    @media (max-width: $md) {
      position: none;
      overflow-y: scroll;
    }
  }
}
// ===========================================================
// missing item popup
.founderror2 {
  display: flex;
  flex-direction: column;
  height: 70vh;
}
.founderror2 .closeicon2 {
  color: #537bb8;
  font-size: 25px;
  position: absolute;
  top: 0;
  right: 0;
}

.founderror2 .missingcontent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 83%;
}
.founderror2 .missingcontent .missingcontentrow1 {
  padding: 10px;
  border: 1px solid #707070;
  border-radius: 10px;
  width: 45%;
  color: #537bb8;
}
.founderror2 .missingcontent .missingcontentrow1 p {
  color: black !important;
}
.founderror2 .missingcontent .missingcontentrow2 {
  padding: 10px;
  border: 1px solid #707070;
  border-radius: 10px;
  width: 52%;
  color: #537bb8;
  height: 100%;
}
.founderror2 .missingcontent .missingcontentrow2 .supportingimages {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: scroll;
  height: 90%;
}
.founderror2 .missingcontent .missingcontentrow2 .supportingimages img {
  width: 45%;
  margin-bottom: 10px;
}
.founderror2 hr {
  background-color: #707070;
}

.resolvebutton {
  display: flex;
  justify-content: right;
  width: 100%;
}

// toolpit
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.search-text-main {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  //padding-right: 10px;

  .search-text {
    border: none;
    padding-left: 13px;
    border-radius: 6px;
    height: 40px;
    font-size: 14px;
    background-color: #e7ecf6;
    width: 25%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .search-text::placeholder {
    color: $black;
  }

  .searchBtn {
    //position: absolute;
    justify-content: center;
    align-items: inherit;
    height: 40px;
    width: 40px;
    margin-right: 5px;
  }
}

.shortid {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  /* -webkit-box-orient: vertical; */
  width: 120px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.Iconscroll {
  overflow: scroll;
  height: 85%;
  width: 100%;
}
button.savebutton.disablebittonop {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: unset;
}

.palreadysummited {
  text-align: left;
  display: flex;
  justify-content: flex-start;
  font-size: 15px;
  color: #537bb8;
}

.bordertr p {
  // text-transform: capitalize;
}

.selecterrors input {
  overflow: visible;
  border: unset;
  // border-bottom: 1px solid grey;
  // padding-left : 5px;
  padding: inherit;
  background: lightgray;
  width: 100%;
}
.foundErrorsTable {
  width: 70%;
}

.foundErrorsTable table,
.ShowErrorPopup table {
  width: 95% !important;
}
.foundErrorsTable table tr td,
.ShowErrorPopup table tr td {
  text-align: left !important;
}
.foundErrorsTable table tr,
.ShowErrorPopup table tr {
  border-bottom: 1px solid #707070;
}
.dropzoneIcon {
  width: 50px;
  height: auto;
  color: lightgray;
}

.noclaimdata table {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newButton {
  margin-top: 50px;
  padding: 5px;
  margin-left: 50px;
  background: #6a9eea;
  color: #fff;
  border: 0px solid;
}

// MODLING DASHBOARD

.accesslinkModling {
  padding: 10px 0px !important;
  margin: 0px;
  text-align: center !important;
}
.accesslinkModling img {
  height: 25px !important;
  margin-right: 2.3px !important;
  padding-right: 2px;
}

.npspacModling {
  text-align: center !important;
  padding: 4px !important;
  width: 51px !important;
}
.npspacforheaderModling {
  text-align: center !important;
  padding: 10px 10px !important;
  width: 51px !important;
}
.npspacModling .npspacimgnpspacModling {
  width: 33px !important;
  margin-right: 0px !important;
  height: 25px !important;
}

.starIconModling {
  width: 25px !important;
  margin-right: 0px !important;
  height: 25px !important;
}
.commentIconModling {
  width: 25px !important;
  margin-right: 0px !important;
  height: 25px !important;
  color: #537bb8;
  cursor: pointer;
}

// overlay
#comments-popover {
  width: 100%;
  max-width: 320px;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 0px 8px 0px #5a5a5a40;

  img {
    cursor: pointer;
  }
  .popover__footer {
    .clear__filter {
      // font-family: $poppins-regular;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      background: transparent;
      color: #6a9eea;
      border: none;
    }
    .apply__filter {
      // font-family: $poppins-regular;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      background: #6a9eea;
      color: #ffffff;
      outline: none;
      border: none;
      padding: 8px 16px;
      width: 100%;
      max-width: 112px;
      border-radius: 10px;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .filter__title {
    background: #ffffff;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #6a9eea;
  }
}

.overlayBackground {
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
}
