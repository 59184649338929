@font-face {
    font-family: 'Roboto Light';
    src: local('Roboto Light'), url('/assets/fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url('/assets/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Medium';
    src: local('Roboto Medium'), url('/assets/fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Bold';
    src: local('Roboto Bold'), url('/assets/fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    src: local('Lato'), url('/assets/fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato Italic';
    src: local('Lato Italic'), url('/assets/fonts/Lato-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato Bold';
    src: local('Lato Bold'), url('/assets/fonts/Lato-Bold.ttf') format('truetype');
}
