.popup-box {
    position: fixed;
    background: rgba(0,0,0,0.7);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0px !important;
  }
  .popup2  {
    position: relative;
    width: 65% !important;
    height: auto;
    max-height: 70vh;
    background: #fff;
    border-radius: 25px;
    box-shadow: #999;
    padding: 30px;
    border: 1px solid #999;
    overflow: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 16px 18px 38px #0000006b;
    border: 1px solid #707070;
  }
   
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(15% - 30px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }

  .popup-box.qa {}
  .popup-box.Modeling .box {
        width: 30% !important;
  }