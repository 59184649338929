@import "../../styles/abstract/variables";

.Complete{
    justify-content: center;
}

.complete-btn{
    font-size: 20px;
    border: solid $light-gray-color;
    height: 50px;
    width: 200px;
    background-color: $light-blue-color;
    cursor: pointer;
}