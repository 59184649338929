@import "../../styles/abstract/variables";
.Complaint-section {
  //   border: 2px solid $gray-color;
  padding: 10px;

  .form-check {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .form-check-input[type="checkbox"] {
      width: 20px;
      height: 20px;
    }
    .form-check-label {
      background: $yellow;
      // padding: 5px 10px;
      height: auto;
      padding: 5px;
      // min-height: 40px;
      margin-left: 20px;
      width: 100%;
      max-width: 160px;
      font-family: $OpenSans-Regular;
      // font-weight: 600;
      font-size: 10px;
      border-radius: 5px;
      border: 1px solid $black-color;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
}
