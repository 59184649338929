@import "../../styles/abstract/variables";
.image-wrapper {
  flex: 50%;
  padding: 10px;
  overflow: auto;
  img {
    height: auto;
    width: 100%;
    object-fit: cover;
  }
}
