* {
  outline: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

#interface-variables .variable_box .variable_form .form_group .form_title {
  font-size: 18px !important;
}

.extraspack {
  // margin-top: 35px;
}

.termsc {
  a {
    // color: unset !important;
  }
  margin-top: 20px;
  display: flex;
  font-size: 14px;

  span {
    margin-left: 10px;
  }

  input {
    display: block;
    zoom: 1.6;
    border-radius: 20px;

    box-shadow: unset !important;
  }
}

.chce-error {
  margin-top: 10px;
}

.checkhigh {
  display: flex;

  p {
    margin-right: 40px !important;
  }
}

.checkhighp {
  margin-right: 40px !important;
}

.modelzoomeffect {
  .close {
    color: #fff !important;
    padding: 0;
    margin: 0;
  }
  .modal-header {
    position: absolute;
    top: 25px;
    right: 3%;
    transform: translate(-50%, -50%);
    background: black;
    border-radius: 10px !important;
    padding: 20px !important;
    left: unset;
    @media (max-width: $sm) {
      right: -8%;
    }
    @media (max-width: 340px) {
      right: -11%;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      right: -15%;
      padding: 15px !important;
    }
  }
}
#carousel-tabs .floor_tab {
  border-radius: 0 0 0 0 !important;
  -webkit-border-radius: 0 0 0 0 !important;
  -moz-border-radius: 0 0 0 0 !important;
  -ms-border-radius: 0 0 0 0 !important;
  -o-border-radius: 0 0 0 0 !important;
}
.input-textadd {
  border: none;
  padding-left: 13px;
  border-radius: 6px;
  height: 42px;
  font-size: 28px;
  background-color: #e7ecf6;
}

@media (min-width: 769px) {
  .roomps .modal-content {
    max-width: 50%;
    width: 100%;
    display: block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.searchBtn p {
  margin-bottom: 0 !important;
}

.roomdd {
  .modal-dialog-centered {
    max-width: 800px !important;
  }
}

.photos_tab {
  svg {
    margin-left: 10px;
  }
}

.fgd {
  margin-top: -30px;
}

.buttondonload {
  .close {
    z-index: 9999999;
    position: relative;
    opacity: 1 !important;
  }
}
.processing-btn {
  padding: 6px 10px !important;
  color: #fff !important;
  margin-top: 5px !important;
}
.processing-btn p {
  margin-top: 0rem !important;
  color: #fff !important;
  font-size: 15px !important;
}
.container {
  max-width: 90% !important;
}
.highlighted-text {
  color: $dark-blue-color;
  font-family: $roboto-bold;
  font-weight: 600;
}
.section-title {
  h2 {
    font-family: $OpenSans-Regular;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 30px;
  }
}
